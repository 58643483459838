import User from '../models/User'

export default {
  state: {
    // Do no use process.env.VUE_APP_APP_URL because it multi-domain app
    VUE_APP_APP_URL: window.location.origin,
    isShow3DPopup: false,
    swiperItems: [],
    swiperOptions: {},
    currentUser:
      localStorage.currentUser != null
        ? new User(JSON.parse(localStorage.currentUser))
        : null,
    token: localStorage.token != null ? localStorage.token : null,
    language: localStorage.language != null ? localStorage.language : null,
    addToListUser: null,
    buyItem: null,
    updates: {
      notifications: 0,
      messages: 0,
    },
    spinner: false,
    pusher: null,
    languageToTranslate: localStorage.language || '',
    isLanguageTranslateTurnOn: false,
    publicUser: null,

    userFilter: {
      locationGroups: [],
      categories: [],
      locations: [],
      sorting: [],
      selectedCategories: [],
      selectedLocations: [],
      selectedSorting: 2,
    },
    homePage: {
      page: 1,
      scrollY: 0,
      posts: [],
      hasMore: false,
    },
    creatorsPage: {
      page: 1,
      scrollY: 0,
      creators: [],
      hasMore: false,
    },

    userPage: {
      user: null,
      contentType: null,

      page: 1,
      scrollY: 0,
      posts: [],
      hasMore: false,

      pageMedias: 1,
      scrollYMedias: 0,
      medias: [],
      hasMoreMedias: false,
      mediaType: null,
      idOfPost: null,
      reportReasons: null,
      deletePostObject: null,
    },
    isVisibleWalletRechargeModal: false,
  },
  getters: {
    user: (state) => state.currentUser,
  },
  mutations: {
    SET_LANGUAGE_TO_TRANSLATE(state, language) {
      state.languageToTranslate = language
    },
    SET_LANGUAGE_TRANSLATE_STATUS(state, status) {
      state.isLanguageTranslateTurnOn = status
    },
    setUser(state, data) {
      state.currentUser = data
    },
    setPublicUser(state, data) {
      state.publicUser = data
    },
    setIsShow3DPopup(state, data) {
      state.isShow3DPopup = data
    },

    setBuyItem(state, data) {
      state.buyItem = data
    },

    setUserFilter(state, data) {
      state.userFilter = data
    },

    setHomePage(state, data) {
      state.homePage = data
    },

    setCreatorsPage(state, data) {
      state.creatorsPage = data
    },

    setUserPage(state, data) {
      state.userPage = data
    },

    setUpdates(state, data) {
      state.updates = data
    },

    setLanguage(state, data) {
      state.language = data
    },
    setIdOfPost(state, data) {
      state.idOfPost = data
    },
    setReportReasons(state, data) {
      state.reportReasons = data
    },
    setDeletePost(state, data) {
      state.deletePostObject = data
    },
    setIsVisibleWalletRechargeModal(state, data) {
      state.isVisibleWalletRechargeModal = data
    },
  },
}
