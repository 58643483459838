<template>
  <div
    class="ios-download-app"
    v-if="isShowIOSDownloadAPP"
    :class="{ 'close-animation': showCloseAnimation }"
    @click="close"
  >
    <span class="ios-download-app__content">
      <span>
        {{ $t('general.ios-download-app-tap') }}
        <img src="@/assets/ios-context-menu.svg" alt="Fanspicy" />
        {{ $t('general.ios-download-app-and') }}
        <img src="@/assets/ios-add.svg" alt="Fanspicy" />
        {{ $t('general.ios-download-app-home') }}
      </span>
    </span>

    <span class="ios-download-app__close">
      <img src="@/assets/icons/new-design/nd-close.svg" alt="close" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'IOSDownloadAPP',

  data() {
    return {
      isVisible: false,
      showCloseAnimation: false,
      count: null,
      type: null,
    }
  },

  computed: {
    isMobileSafari() {
      const userAgent = navigator.userAgent
      return (
        /AppleWebKit/.test(userAgent) &&
        /Mobile/.test(userAgent) &&
        !/Chrome/.test(userAgent)
      )
    },

    isShowIOSDownloadAPP() {
      if (
        this.$isStandaloneIOS() ||
        !navigator.userAgent.match(/iPhone/) ||
        !this.isMobileSafari ||
        !this.isVisible
      )
        return false

      return true
    },
  },

  mounted() {
    this.show()
  },

  methods: {
    show() {
      if (!localStorage.countClickToCloseIOSDownload)
        localStorage.setItem('countClickToCloseIOSDownload', 0)

      this.count = localStorage.countClickToCloseIOSDownload
      this.type = typeof localStorage.countClickToCloseIOSDownload

      if (Number(localStorage.countClickToCloseIOSDownload) >= 2) return

      setTimeout(() => {
        this.isVisible = true
      }, 2000)
    },

    close() {
      localStorage.countClickToCloseIOSDownload++
      this.showCloseAnimation = true
      setTimeout(() => {
        this.isVisible = false
        this.showCloseAnimation = false
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.ios-download-app {
  position: fixed;
  bottom: 10px;
  left: 20px;
  right: 20px;
  z-index: 9999999;
  background-color: rgb(224 224 224);
  //-webkit-backdrop-filter: blur(10px);
  //backdrop-filter: blur(10px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding: 10px 30px 10px 18px;
  font-size: 11px;
  border-radius: 10px;
  color: #525151;
  margin: 0;
  height: 58px;

  //animate showing from bottom to top
  animation: slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  &.close-animation {
    animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &::before {
    display: inherit;
    content: ' ';
    position: absolute;
    top: calc(100% - 5px);
    left: 50%;
    margin-left: -10px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: rgb(224 224 224);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.05);
    //backdrop-filter: blur(10px);
    z-index: 9999998;
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(100px);
      opacity: 0;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: white;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 6px;
      height: 6px;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin: 0 2px;
    }
  }
}
</style>
