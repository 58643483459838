<template>
  <div
    class="in-app-browser"
    :style="{ backgroundImage: `url(${bgImage})` }"
    style="background-size: cover"
    :class="{ arabic: isArabic }"
  >
    <div class="line1">
      <div class="step-one-block">
        <div>
          <div
            class="text-title"
            v-html="$t('general.in-app-browser-title')"
          ></div>
          <br />
          <br />
          <div class="text-small">
            <span class="round-label">1</span>
            {{ $t('general.in-app-browser-step1') }}
          </div>
        </div>
        <div>
          <img
            src="@/assets/icons/arr-step1.svg"
            alt="Fanspicy"
            :style="arrStep1Styles"
          />
        </div>
      </div>
      <div class="text-large">
        {{ $t('general.in-app-browser-click-dots') }}
      </div>
    </div>
    <br />
    <div class="line3">
      <div class="text-small">
        <span class="round-label">2</span>
        {{ $t('general.in-app-browser-step2') }}
      </div>
      <div class="text-large">
        {{ $t('general.in-app-browser-browser') }}
      </div>
      <div style="display: flex; padding: 10px 50px 50px 50px">
        <img src="@/assets/icons/arr-step2.svg" alt="Fanspicy" />
      </div>
      <div class="browser-menu">
        <img src="@/assets/icons/menu-browser.svg" alt="Fanspicy" />
        {{ $t('general.in-app-browser-open-browser') }}
      </div>
    </div>
  </div>
</template>
<script>
import { langs } from '@/components/helpers/langs'
export default {
  name: 'UiOpenInBrowser',

  data() {
    return {
      bgImage: require('@/assets/icons/bg-balls.svg'),
      lang1: '++',
    }
  },

  computed: {
    isArabic() {
      return window?.navigator?.language.includes('ar')
    },

    arrStep1Styles() {
      return this.isArabic
        ? 'position: relative; left: -14px'
        : 'position: relative; right: -14px'
    },

    lang2() {
      return window?.navigator?.language
    },
  },

  created() {
    let lang = langs.find((item) =>
      window?.navigator?.language.includes(item.value)
    ) || { value: 'en' }
    localStorage.setItem('language', lang.value)
    this.$store.commit('setLanguage', lang.value)
    this.$i18n.locale = lang.value
    this.lang1 = lang.value
  },
}
</script>

<style lang="scss">
.in-app-browser {
  position: static;
  left: 0;
  top: 0;
  right: 0;
  //bottom: 0;
  z-index: 99999;
  background: #f5f5fb;
  background-size: cover;
  min-height: 100vh !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.arabic {
    direction: rtl;
  }

  .line1 {
    margin-top: 5px;
    width: 100%;
    padding: 0 15px;

    .step-one-block {
      display: flex;
      justify-content: space-between;
    }
  }

  .line3 {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 115px;
  }

  .round-label {
    background: #fff;
    border: 1px solid #02b5f5;
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.45));
    width: 76px;
    height: 76px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #02b5f5;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
  }

  .text-title {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px;
    word-break: break-all;
  }

  .text-small {
    color: #cdf6fc;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: fit-content;
  }

  .text-large {
    color: #fff;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 22px;
    border: 1px solid #fff;
    background: #02b5f5;
    box-shadow: 0px 0px 30px 0px #fff;
    padding: 8px 16px;
    margin: 16px 0;
  }

  .browser-menu {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    box-shadow: 0px 0px 10px 0px #fff;
    height: 45px;

    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
