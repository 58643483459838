import Bundle from './Bundle'
import { currencySymbols } from '@/components/helpers/currencies'

export default class User {
  static ROLE_USER = 0
  static ROLE_CREATOR = 1
  static ROLE_ADMIN = 10

  static CHANNEL_EMAIL = 0
  static CHANNEL_GOOGLE = 1

  id = 0
  name = ''
  username = ''
  avatar = null
  cover = null
  active_offer = null
  role = User.ROLE_USER
  bio = null
  location = null
  website = null
  price = 0
  bundles = []
  isSubscribed = false
  isVerify = false
  counted_info = null
  filter_data = {}
  tax_percentage = null
  online = {}
  categories = []
  payment_level = null
  payment_level_text = null
  is_followed = false
  hidden_status = false
  verificate = false
  is_discount = false
  socials = null
  currency = null
  twitter = false
  twitter_name = null
  veriffLink = null
  typeVerification = null
  allow_public_access = null

  constructor(data) {
    if (!data) return

    this.id = data.id
    this.name = data.name
    this.username = (data.username || '').toLowerCase()
    this.avatar = data.avatar
    this.cover = data.cover
    this.active_offer = data.active_offer
    this.role = data.role
    this.bio = data.bio ? data.bio : null
    this.location = data?.location || { id: '', name: '', image: '' }
    this.website = data.website ? data.website : null
    this.price = data.price ? data.price : null
    this.isSubscribed = data.is_subscribed
    this.isVerify = data.is_verify || data.isVerify
    this.counted_info = data?.counted_info || {
      likes: 0,
      subscribers: 0,
      posts: 0,
      medias: 0,
      photos: 0,
      videos: 0,
    }
    this.filter_data = data?.filter_data || {
      filter_languages: [],
      filter_applied: [],
    }
    this.tax_percentage = data?.tax_percentage || null
    this.online = data?.online || { isOnline: false, message: '' }
    this.categories = data?.categories || []
    this.image_flag = data?.image_flag || null
    this.payment_level = data?.payment_level || null
    this.payment_level_text = data?.payment_level_text || null
    this.is_followed = data?.is_followed || null
    this.hidden_status = data?.hidden_status ? true : false
    this.verificate = data.verificate
    this.is_discount = data.is_discount
    this.profile_percent_data = data?.profile_percent_data || null
    this.socials = data?.socials || null
    this.currency = data?.currency || null
    this.twitter = data?.twitter || false
    this.twitter_name = data?.twitter_name || null
    this.veriffLink = data?.veriffLink || null
    this.typeVerification = data?.veriffLink || null
    this.allow_public_access = data?.allow_public_access || null

    if (data.bundles) {
      for (let b of data.bundles) {
        this.bundles.push(new Bundle(b))
      }
    }
  }

  get initials() {
    const a = this.username.charAt(0).toUpperCase()
    const b = this.username.charAt(1)
    return a + b
  }

  get url() {
    return `/${this.username}`
  }

  get isFree() {
    return !this.price && true
  }

  get priceFormatted() {
    const currentCurrency = JSON.parse(localStorage.currentUser).currency
    return currencySymbols[currentCurrency] + this.price
  }

  get isAdmin() {
    return this.role == User.ROLE_ADMIN
  }

  get isCreator() {
    return this.role == User.ROLE_CREATOR || this.isAdmin
  }
}
