import dayjs from "dayjs";
import mapValues from 'lodash/mapValues';
import stores from '@/components/helpers/Store';

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export function decode(str) {
    const uid = stores.state?.currentUser?.id || 0

    let url = str;
    if(typeof url === 'string') {
        try {
            url = atob(str.split("").reverse().join("")).replace(`|${uid}`, '');
        } catch (err) {
            url = str
        }
    }


    return url
}

export default class Media {

    static TYPE_IMAGE = 0;
    static TYPE_VIDEO = 1;
    static TYPE_AUDIO = 2;
    static TYPE_GIF = 3;

    id = 0;
    type = Media.TYPE_IMAGE;
    encode = null;
    created_at = dayjs();
    url = "";
    screenshot = "";
    screenshot_blur = "";
    trailers = "";
    total_media_files = 0;
    total_media_files_encoded = 0;
    urls = "";
    thumbs = []
    height = null;
    width = null;

    constructor(data) {
        if (!data) {
            return;
        }

        this.id = data.id;
        this.type = data.type;
        this.encode = data.encode;
        this.created_at = dayjs(data.created_at);
        this.url = this.decode(data.url);
        this.setScreenshot(data.screenshot)
        this.setScreenshotBlur(data.screenshot_blur)
        this.setTrailers(data.trailers);
        this.setUrls(data.urls);
        this.total_media_files = data.total_media_files;
        this.total_media_files_encoded = data.total_media_files_encoded;
        this.width = data.width;
        this.height = data.height;
    }

     decode(str) {
        return decode(str)
    }

    setTrailers(data) {
        this.trailers = mapValues(data, trailer => this.decode(trailer))
    }

    setUrls(data) {
        this.urls = mapValues(data, url => this.decode(url))
    }

    setScreenshot(data) {
        if(data) {
            let screenshot = this.decode(data)
            if(typeof screenshot === 'string'  && !screenshot.includes(process.env.VUE_APP_STORAGE_URL)){
                screenshot = process.env.VUE_APP_STORAGE_URL + screenshot
            }
            this.screenshot = screenshot
        }
    }

    setScreenshotBlur(data) {
        if(data) {
            let screenshot_blur = this.decode(data)
            if (typeof screenshot_blur === 'string' && !screenshot_blur.includes(process.env.VUE_APP_STORAGE_URL)) {
                screenshot_blur = process.env.VUE_APP_STORAGE_URL + screenshot_blur
            }
            this.screenshot_blur = screenshot_blur
        }
    }


}