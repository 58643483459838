export const langs = [
  { text: 'عربية', value: 'ar' },
  { text: 'English', value: 'en' },
  { text: 'Portugeese', value: 'pt' },
  { text: 'Romania', value: 'ro' },
  { text: 'Русский', value: 'ru' },
  { text: '한국인', value: 'ko' },
  { text: 'Svenska', value: 'sv' },
  { text: 'Español', value: 'es' },
  { text: 'Italiano', value: 'it' },
]