<template>
  <div id="app">
    <template v-if="!isInAppBrowser">
      <router-view></router-view>
      <ui-cookies />
      <UiIOSDownloadAPP v-if="!isVirtualBrowser" />
    </template>
    <UiOpenInBrowser v-else />
  </div>
</template>
<script>
import UiCookies from './components/ui/UiCookies.vue'
import UiOpenInBrowser from './components/ui/UiOpenInBrowser.vue'
import UiIOSDownloadAPP from './components/ui/UiIOSDownloadAPP.vue'

export default {
  components: { UiCookies, UiOpenInBrowser, UiIOSDownloadAPP },
  metaInfo() {
    return {
      link: [
        {
          href: this.$store.state.VUE_APP_APP_URL + this.$route.fullPath,
          rel: 'canonical',
        },
      ],
    }
  },

  computed: {
    isVirtualBrowser() {
      const isInstagramInAppBrowser = () => {
        return (
          navigator.userAgent.match(/instagram/i)
        );
      }
      return isInstagramInAppBrowser()
    },
    isInAppBrowser() {
    const isTikTokInAppBrowser = () => {
      return (
        navigator.userAgent.match(/musical_ly/) &&
        ((document?.referrer || '').match(/tiktok/) ||
          (document?.referrer || '').match(/tik-tok/))
      )
    }



    // const isInstagramInAppBrowser = () => {
    //   // for android
    //   if (
    //     navigator.userAgent.match(/Android/) &&
    //     navigator.userAgent.match(/Instagram/) &&
    //     (document?.referrer || '').match(/instagram/)
    //   ) {
    //     return true
    //   }
    //   // for Iphone. 1 version
    //   if (
    //     navigator.userAgent.match(/iPhone/) &&
    //     !navigator.userAgent.match(/Safari/) &&
    //     !navigator.userAgent.match(/Chrome/) &&
    //     !navigator.userAgent.match(/Opera/) &&
    //     (document?.referrer || '').match(/instagram/)
    //   ) {
    //     return true
    //   }
    //   // for Iphone. 2 version
    //   if (
    //     navigator.userAgent.match(/iPhone/) &&
    //     navigator.userAgent.match(/Instagram/) &&
    //     (document?.referrer || '').match(/fbclid=/)
    //   ) {
    //     return true
    //   }
    //   return false
    // }

    return isTikTokInAppBrowser()
  },
},

created() {
  if (process.env.NODE_ENV !== 'development') {
    console.log('%c!!!Please STOP!!!', 'font-size: 80px; color:red')
  }
},
}
</script>
