export const analytics = {
  modelVerification: ({ username, dateVerification, userId }) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'model-verification',
      user: {
        userId: userId,
        username: username,
        dateVerification: dateVerification,
      },
    })
  },

  paymentFinished: (data) => {
    if (!data?.payment_data) {
      console.warn('paymentFinished data is empty', data)
      return
    }

    const result = data.payment_data

    if (result?.payment?.status !== 'success') {
      console.warn('Payment status for analytics =>', result?.payment?.status)
      return
    }

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: result?.payment?.transactionId,
        affiliation: window.location.origin,
        value: result?.payment?.amount,
        currency: result?.payment?.currency,
        items: [
          {
            item_name: 'subscription' in result ? 'subscription' : 'other',
            item_id: result?.payment?.orderId,
            price: '' + result?.payment?.amount,
            // item_brand: 'Google',
            // item_category: 'Apparel',
            // item_variant: 'Gray',
            quantity: 1,
          },
        ],
      },
    })
  },

  paymentFinishedTrafficjunky: (data) => {
    //https://ads.trafficjunky.net/ct?a=1000460241&member_id=1005054301&cb=[RANDOM_NUMBER]&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]
    //&cb=[RANDOM_NUMBER]
    //&cti=[TRANSACTION_UNIQ_ID]
    //&ctv=[VALUE_OF_THE_TRANSACTION]
    //&ctd=[TRANSACTION_DESCRIPTION

    if (!data?.payment_data) {
      console.warn('paymentFinishedTrafficjunky data is empty', data)
      return
    }

    const result = data.payment_data

    if (result?.payment?.status !== 'success') {
      console.warn('Payment status for analytics =>', result?.payment?.status)
      return
    }

    // let imgElement = document.querySelector('1000460241_cpa_testing')
    // if (!imgElement) {
    //   console.warn('Do not found Trafficjunky')
    //   return
    // }

    // let src = imgElement.getAttribute('src')
    // let url = new URL(src)

    // const randomNumber =
    //   Math.floor(Math.random() * 100000000000) +
    //   Math.floor(Math.random() * 100000000000)

    // url.searchParams.set('cb', randomNumber)
    // url.searchParams.set('cti', result?.payment?.transactionId)
    // url.searchParams.set('ctv', result?.payment?.amount)
    // url.searchParams.set('ctd', 'purchase')
    // imgElement.setAttribute('src', url.toString())

    //<img id="1000460241_cpa_testing" src="https://ads.trafficjunky.net/ct?a=1000460241&member_id=1005054301&cb=
    //[RANDOM_NUMBER]&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]"
    //width="1" height="1" border="0" />

    //<img id="1000466141_cpa_testing" src="https://ads.trafficjunky.net/ct?a=1000466141&member_id=1005054301&cb=
    //[RANDOM_NUMBER]&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]"
    //width="1" height="1" border="0" />

    var randomNumber =
      Math.floor(Math.random() * 100000000000) +
      Math.floor(Math.random() * 100000000000)

    var img = document.createElement('img')
    img.setAttribute('id', '1000466141_cpa_testing')
    img.setAttribute(
      'src',
      `https://ads.trafficjunky.net/ct?a=1000466141&member_id=1005054301&cb=
      ${randomNumber}&cti=123&ctv=34&ctd=${'purchase'}`
    )
    img.setAttribute('width', '1')
    img.setAttribute('height', '1')
    img.setAttribute('border', '0')
    document.body.appendChild(img)
  },

  viewPageFinishedTrafficjunky: (data, page) => {
    // if (!data?.payment_data) {
    //   console.warn('paymentFinishedTrafficjunky data is empty', data)
    //   return
    // }

    // const result = data.payment_data

    // if (result?.payment?.status !== 'success') {
    //   console.warn('Payment status for analytics =>', result?.payment?.status)
    //   return
    // }

    const randomNumber =
      Math.floor(Math.random() * 100000000000) +
      Math.floor(Math.random() * 100000000000)

    let img = document.createElement('img')
    img.setAttribute('id', '1000460241_cpa_testing')
    img.setAttribute(
      'src',
      `https://ads.trafficjunky.net/ct?a=1000460261&member_id=1005054301&cb=
      ${randomNumber}&cti=${randomNumber}&ctv=${0}&ctd=${'view-page-' + page}`
    )
    img.setAttribute('width', '1')
    img.setAttribute('height', '1')
    img.setAttribute('border', '0')
    document.body.appendChild(img)
  },
}

// let f = {
//   payment: {
//     code: 0,
//     description: 'Approved',
//     action: 'charge',
//     mode: 'sale',
//     status: 'success',
//     amount: 26.4,
//     currency: 'EUR',
//     orderId: '2441352018',
//     transactionId: '514272126',
//     descriptor: 'Sulihighlevel',
//     source: {
//       method: 'card',
//       number: '537410****6957',
//       paymentAccountId: '2683f355-ef90-4fdf-aed6-76a006b3f514',
//       brand: 'mastercard',
//       expirationMonth: '11',
//       expirationYear: '27',
//       disabled: false,
//     },
//     apiPaymentSource: 'token',
//     timestamp: {
//       dateTime: '2023-11-22 13:45:54',
//       timezone: '+01:00',
//       unixTime: 1700657154,
//     },
//   },
//   consumer: {
//     id: '125501248',
//     email: 'kyle.eklid98@gmail.com',
//     firstName: 'MR',
//     lastName: 'KYLE',
//     country: 'GBR',
//   },
//   metadata: { hash: 'O8A5DhKOPkSVUify' },
//   subscription: {
//     id: '190949404',
//     cycle: 0,
//     status: 'active',
//     renewalDate: '2023-12-22 13:45:13',
//     timezone: '+01:00',
//     eventTime: '2023-11-22 13:45:54',
//   },
// }
